'use strict';

module.exports = {
  AUTH_PORTAL: 'AUTH_PORTAL',
  MFA_EMAIL:   'MFA_EMAIL',
  SUCCESS:     'SUCCESS',

  landingPageStorageKey: 'LANDING_PAGE',
  cookieLawStorageKey:   'COOKIE_LAW_HIDDEN',
  nativeClient:          'NATIVE_CLIENT',
  nativeClientVersion:   'NATIVE_CLIENT_VERSION',
  infoStorageKey:        'SHOWN_INFOS',
};
