'use strict';

const _ = require('underscore');
const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const WarningView = require('./components/warningView');
const ErrorView = require('./components/msgBoxView');
const { nameRulesReg, nameRules, getErrorCode } = require('../utils');
const ShowPwdView = require('./components/showPwdView');
const PwdStrengthView = require('./components/pwdStrengthView');

const navChannel = Radio.channel('controller');
const template = require('../templates/trial-setup.hbs');

const fieldsToNotify = ['firstname', 'lastname', 'password', 'confirmpassword'];

const validate = (data) => {
  const pwdReg = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/;
  let errors = [];
  fieldsToNotify.forEach((fieldName) => {
    if (!data[fieldName] || !data[fieldName].length || !data[fieldName].trim().length) {
      errors.push(fieldName);
      errors.push(`${fieldName}-required`);
    } else if (['password', 'confirmpassword'].includes(fieldName) && !pwdReg.test(data[fieldName])) {
      errors.push(fieldName);
      errors.push(`${fieldName}-invalid`);
    } else if (['firstname', 'lastname'].includes(fieldName) && !nameRulesReg.test(data[fieldName])) {
      errors.push(fieldName);
      errors.push(`${fieldName}-invalid`);
    }
  });
  if (data.password !== data.confirmpassword) {
    errors.push('not-match');
  }

  errors = _.uniq(errors);
  if (errors.length) return errors;

  return false;
};

const TrialSetupView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    firstname:       '#firstname',
    lastname:        '#lastname',
    password:        '#password',
    confirmpassword: '#confirmpassword',
    form_group:      '.form-group',
    error:           '.error',
    input_error:     '.input-error',
    showPwd:         '#showPwd',
    pwdStrength:     '.password-strength-container',
    showConfirmPwd:  '#showConfirmPwd',
  },
  events: {
    //   firstname field
    'input @ui.firstname':       'onFocusFirstname',
    'focus @ui.firstname':       'onFocusFirstname',
    'blur @ui.firstname':        'onBlurFirstname',
    //   lastname field
    'input @ui.lastname':        'onFocusLastname',
    'focus @ui.lastname':        'onFocusLastname',
    'blur @ui.lastname':         'onBlurLastname',
    //    password field
    'input @ui.password':        'onChangePassword',
    'focus @ui.password':        'onFocusPassword',
    'blur @ui.password':         'onBlurPassword',
    //    confirm password field
    'input @ui.confirmpassword': 'onFocusConfirmpassword',
    'focus @ui.confirmpassword': 'onFocusConfirmpassword',
    'blur @ui.confirmpassword':  'onBlurConfirmpassword',

    'submit form': 'handleSubmit',
    change:        'onRender',
    rendered:      false,
  },

  initialize: function fInitialize({ model, inviteKey, invitedBy }) {
    this.model = model;
    this.model.set({
      inviteKey,
      invitedBy,
      passwordRules: this.passwordRules,
      nameRules,
    });
  },

  onFocusFirstname: function fOnFocusFirstname() {
    this.ui.firstname.parent().removeClass('has-error');
  },

  onFocusLastname: function fOnFocusLastname() {
    this.ui.lastname.parent().removeClass('has-error');
  },

  onFocusPassword: function fOnFocusPassword() {
    this.ui.password.parent().removeClass('has-error');
  },

  onChangePassword: function fOnChangePassword() {
    this.onFocusPassword();
    this.pwdStrengthView.evaluate(this.ui.password.val());
  },

  onFocusConfirmpassword: function fOnFocusConfirmPassword() {
    this.ui.confirmpassword.parent().removeClass('has-error');
  },

  passwordRules: function fPasswordRules() {
    return [
      i18n.t('common:password-rules-minchars'),
      i18n.t('common:password-rules-lowercase'),
      i18n.t('common:password-rules-uppercase'),
      i18n.t('common:password-rules-numerical'),
      i18n.t('common:password-rules-special'),
      i18n.t('common:password-rules-confirm-match'),
    ];
  },

  handleSubmit: function fHandleSubmit(event) {
    event.preventDefault();

    this.model.set({
      firstname:       this.ui.firstname.val(),
      lastname:        this.ui.lastname.val(),
      password:        this.ui.password.val(),
      confirmpassword: this.ui.confirmpassword.val(),
    });

    const errors = validate(_.pick(this.model.attributes, fieldsToNotify));
    if (errors) {
      const f = fieldsToNotify.find((field) => {
        if (errors.includes(`${field}-required`)) {
          this.warningView.show(i18n.t(`common:${field}-required`));
          return true;
        }
        return false;
      });
      if (f) {
        // this.warningView.show(i18n.t('common:password-required'));
      } else if (errors.includes('firstname-invalid')) {
        this.warningView.show(i18n.t('common:firstname-invalid'));
      } else if (errors.includes('lastname-invalid')) {
        this.warningView.show(i18n.t('common:lastname-invalid'));
      } else if (errors.includes('password-invalid')) {
        this.warningView.show(i18n.t('common:password-invalid'));
      } else if (errors.includes('confirmpassword-required')) {
        this.warningView.show(i18n.t('common:confirmpassword-required'));
      } else if (errors.includes('not-match')) {
        this.warningView.show(i18n.t('common:password-do-not-match'));
      }

      _.each(fieldsToNotify, function fieldsIterator(fieldName) {
        if (errors.includes(fieldName)) {
          this.setInputError(this.$el.find(`#${fieldName}`));
        }
      }, this);
    } else {
      $.ajax({
        url:  `${NETOP_CONFIG.SECURE_API}/trial-setup/${this.model.get('setupToken')}`,
        data: {
          firstname:       this.model.get('firstname'),
          lastname:        this.model.get('lastname'),
          password:        this.model.get('password'),
          confirmPassword: this.model.get('confirmpassword'),
        },
        method: 'POST',
      }).done((authResponse) => {
        this.model.set({ token: authResponse.token });
        navChannel.trigger('SUCCESS');
      }).fail((response) => {
        const errCode = getErrorCode(response);
        if (errCode === '1.16') {
          this.warningView.show(i18n.t('common:incorrect-password'));
          this.setInputError(this.$el.find('#password'));
        } else {
          this.warningView.show(i18n.t('common:info-not-saved'));
        }
      });
    }
  },

  setInputError: function fSetInputLabel(el) {
    el.each((index, elem) => {
      const $el = $(elem);
      $el.parent().addClass('has-error');
    });
  },

  showPwdView:        null,
  showConfirmPwdView: null,
  pwdStrengthView:    null,

  onAttach: function fAttach() {
    this.showPwdView = new ShowPwdView({
      el:     this.ui.showPwd,
      target: this.ui.password,
    });
    this.showPwdView.render();
    this.showConfirmPwdView = new ShowPwdView({
      el:     this.ui.showConfirmPwd,
      target: this.ui.confirmpassword,
    });
    this.showConfirmPwdView.render();
    this.pwdStrengthView = new PwdStrengthView({
      el: this.ui.pwdStrength,
    });
    this.pwdStrengthView.render();
  },

  onRender: function fOnRender() {
    if (!this.rendered) {
      setTimeout(() => this.ui.password.focus(), 1); // Doesn't work without timeout, cba with proper fix
    }
    this.rendered = true;
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
    this.warningView = new WarningView({
      el: this.ui.input_error,
    });

    this.$el.find('.has-tooltip').popover({ trigger: 'hover' });
  },
});

module.exports = TrialSetupView;
