'use strict';

const Marionette = require('backbone.marionette');
const template = require('../templates/stopError.hbs');

const StopError = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
  },

  events: {
  },
});

module.exports = StopError;
