var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"nativeClient") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "            <p>&nbsp;</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"returnPage") : depth0), depth0))
    + "\" class=\"link-sm\" >"
    + alias1(__default(require("../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"common:back-to-portal",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":54},"end":{"line":17,"column":86}}}))
    + "</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"form-group\">\n            <a href=\"#\" class=\"link-sm\" id=\"cancel\">"
    + container.escapeExpression(__default(require("../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"common:change-acc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":52},"end":{"line":21,"column":80}}}))
    + "</a>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <div><div class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:email-title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":62}}}))
    + "</div></div>\n    <div class=\"center-block username\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</div>\n    <form>\n        <div class=\"error\"></div>\n        <div class=\"input-icon\">\n            <label class=\"control-label\" for=\"mfaRecoveryCode\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:recovery-code-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":100}}}))
    + "</label>\n            <input class=\"input\" id=\"mfaRecoveryCode\">\n        </div>\n        <div class=\"input-error\"></div>\n        <button type=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":47},"end":{"line":11,"column":69}}}))
    + "</button>\n    </form>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"switchAccount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});