'use strict';

const i18n = require('i18next');

const mapErrorCodeToMessage = (code, defaultKey = 'error:unknown') => {
  code = parseInt(code, 10); // eslint-disable-line
  let range = null;
  const specificCodes = [20004, 20016];

  if (!specificCodes.includes(code)) {
    if (code >= 20000 && code < 30000) {
      range = 'adfs-range';
    }
    if (code >= 61000 && code < 61100) {
      range = 'ldap-range';
    }
  }
  let i18nMappedError = ['error', (range || code)].join(':');
  if (!i18n.exists(i18nMappedError)) {
    i18nMappedError = defaultKey;
  }
  return i18n.t(i18nMappedError, { code });
};

const getErrorCode = (response) => {
  let errorCode = 0;
  if (response.responseJSON && response.responseJSON && response.responseJSON.error_code) {
    errorCode = response.responseJSON.error_code;
  }
  return errorCode;
};

const nameRulesReg = /^[^=+-@\t\r\f\n\s]/;

const nameRules = () => [
  i18n.t('common:mandatory'),
  i18n.t('common:name-rule'),
];

module.exports = {
  mapErrorCodeToMessage,
  getErrorCode,
  nameRules,
  nameRulesReg,
};
