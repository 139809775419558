var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <h4>"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:success",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":33}}}))
    + "</h4>\n    <h5 class=\"username\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</h5>\n    <form style=\"padding-top:10px;\">\n      <h5>"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:redirecting",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":39}}}))
    + "</h5>\n    </form>\n</div>\n";
},"useData":true});