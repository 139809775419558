'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const template = require('../templates/changepassword-cancel.hbs');

const navChannel = Radio.channel('controller');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/password/cancel`,
  method: 'POST',
};

const ForgotPasswordView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    cancel: '#cancel',
  },

  events: {
    'click @ui.cancel': 'onCancel',
  },

  initialize: function fInitialize() {
    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        token: this.model.get('resetPasswordCode'),
      },
    });
  },

  onCancel: function fOnFocusEmail(event) {
    event.preventDefault();
    navChannel.trigger('USERNAME');
  },
});

module.exports = ForgotPasswordView;
