'use strict';

/* eslint-disable  no-prototype-builtins */

const Backbone = require('backbone');
const _ = require('underscore');

const defaults = {
  dismissable:     true,
  summary:         '',
  startdate:       null,
  highlightExpire: null,
  severity:        'info',
};

const initialize = function fInitialize() {
  const type = this.get('type');
  if (type && _.isObject(type) && type.hasOwnProperty('typeLog') && type.typeLog === 'downtime') {
    this.set('severity', 'warning');
  }
};

module.exports = Backbone.Model.extend({
  defaults,
  initialize,
});
