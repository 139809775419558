'use strict';

const Marionette = require('backbone.marionette');
const Radio = require('backbone.radio');
const template = require('../templates/changepassword-success.hbs');

const navChannel = Radio.channel('controller');

const ForgotPasswordView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    cancel: '#cancel',
  },

  events: {
    'click @ui.cancel': 'onCancel',
  },

  onCancel: function fOnFocusEmail(event) {
    event.preventDefault();
    navChannel.trigger('USERNAME');
  },
});

module.exports = ForgotPasswordView;
