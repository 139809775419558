'use strict';

const Marionette = require('backbone.marionette');
const Radio = require('backbone.radio');
const simpleStorage = require('simplestorage.js');
const _ = require('underscore');
const Constants = require('../Constants');
const template = require('../templates/success.hbs');

const navChannel = Radio.channel('controller');

const View = Marionette.ItemView.extend({
  template,
  tagName:     'div',
  processed:   false,
  modelEvents: {
    change: 'render',
  },
  onRender: function fOnRender() {
    if (!this.processed) {
      this.processed = true;
      this.finalizeInvite()
        .then(() => this.redirect())
        .catch((error) => {
          console.error(error);
          this.model.set({
            nextStep: 'ERROR',
            error:    777,
          });
          navChannel.trigger(this.model.get('nextStep'));
        });
    }
  },
  redirect: function fRedirect() {
    const data = {
      token: this.model.get('token'),
    };
    if (this.model.get('commands')) {
      _.extend(data, {
        commands: this.model.get('commands'),
      });
    }
    const encodedToken = btoa(JSON.stringify(data));

    const storedLandingPage = simpleStorage.get(Constants.landingPageStorageKey);
    if (storedLandingPage) {
      simpleStorage.deleteKey(Constants.landingPageStorageKey);
    }

    const defaultLandingPage = [NETOP_CONFIG.PORTAL_FRONTEND, 'authenticate']
      .join(NETOP_CONFIG.PORTAL_FRONTEND.endsWith('/') ? '' : '/');

    const home = this.model.get('home') ? '&home=1' : '';
    const landingPage = storedLandingPage || defaultLandingPage;
    const redirectUrl = `${landingPage}?token=${encodedToken}${home}`;
    window.location.href = redirectUrl;
  },

  finalizeInvite: function fProcessInvite() {
    // check was an invite login (secondary account)
    if (!this.model.get('inviteLogin')) {
      return Promise.resolve(false);
    }
    const inviteKey = this.model.get('inviteKey');
    if (!inviteKey) {
      // inviteKey not returned from server
      return Promise.reject(new Error('error, missing inviteKey'));
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        url:  `${NETOP_CONFIG.SECURE_API}/invite/${inviteKey}`,
        data: {
          inviteLogin: true,
          token:       this.model.get('token'),
        },
        method: 'POST',
      }).done((authResponse) => {
        this.model.set({
          token: authResponse.token,
          home:  true,
        });
        resolve(true);
      }).fail((error) => {
        console.error('Invite error happened');
        reject(error);
      });
    });
  },
});

module.exports = View;
