var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"error-box alert-neutral\">\n            "
    + container.escapeExpression(__default(require("../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"common:email-message",{"name":"i18n","hash":{"email":((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"email") : stack1)},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":63}}}))
    + "\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"error\"></div>\n            <div class=\"input-error\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "            <div class=\"error\"></div>\n            <div class=\"input-icon\">\n                <label for=\"mfacode\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:email-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":66}}}))
    + "</label>\n                <input type=\"password\" class=\"input\" id=\"mfacode\" autocomplete=\"new-password\">\n            </div>\n            <div class=\"input-error\"></div>\n            <div class=\"mb\">\n                <a href=\"#\" id=\"use-recovery-code\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:use-recovery-code",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":51},"end":{"line":22,"column":86}}}))
    + "</a>\n            </div>\n            <button type=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":51},"end":{"line":24,"column":73}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"nativeClient") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "            <p>&nbsp;</p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"returnPage") : depth0), depth0))
    + "\" class=\"link-sm\" >"
    + alias1(__default(require("../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"common:back-to-portal",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":54},"end":{"line":31,"column":86}}}))
    + "</a>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"form-group\">\n            <a href=\"#\" class=\"link-sm\" id=\"cancel\">"
    + container.escapeExpression(__default(require("../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"common:change-acc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":52},"end":{"line":35,"column":80}}}))
    + "</a>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <div><div class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:email-title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":62}}}))
    + "</div></div>\n    <div class=\"center-block username\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</div>\n"
    + ((stack1 = __default(require("../handlebars-helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),"===","5151",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "    <form>\n"
    + ((stack1 = __default(require("../handlebars-helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),"===","5151",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "    </form>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"switchAccount") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});