'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const _ = require('underscore');
const template = require('../templates/success.hbs');

const navChannel = Radio.channel('controller');
const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/nextStep`,
  method: 'POST',
};

const View = Marionette.ItemView.extend({
  tagName:  'div',
  template,
  onRender: function fOnRender() {
    this.requestNextStep();
  },
  requestNextStep: function fRequestNextStep() {
    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        loginSessionId: this.model.get('loginSessionId'),
        currentStep:    this.model.get('nextStep'),
        input:          this.model.get('nextStep'),
      },
    })
      .done((body) => {
        this.model.set(_.extend({}, body, (body.options || {})));
        if (body.nextStep === 'ERROR') {
          navChannel.trigger(this.model.get('nextStep'));
          return;
        }
        navChannel.trigger(this.model.get('nextStep'));
      })
      .fail((error) => {
        console.error(`Error happened (error code: ${error})`);
        const session = {
          nextStep: 'ERROR',
          error:    777,
        };
        this.model.set(session);
        navChannel.trigger(this.model.get('nextStep'));
      });
  },
});

module.exports = View;
