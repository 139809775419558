var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"invite-notif-container\">\n    <div style=\"display: flex\">\n        <div style=\"margin-right: 20px;\">\n            <i style=\"height: 40px; width: 40px\" class=\"netop-info\"></i>\n        </div>\n        <div>\n            <p style=\"font-size: 22px; padding-top: 2px\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:email-registered",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":57},"end":{"line":7,"column":91}}}))
    + "</p>\n        </div>\n    </div>\n    <div style=\"margin: auto; width: 100px\">\n        <button id=\"goto-login-btn\" type=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:go-to-login",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":96}}}))
    + "</button>\n    </div>\n</div>";
},"useData":true});