'use strict';

const Backbone = require('backbone');

const user = Backbone.Model.extend({
  defaults: {
    username: '',
  },
});

module.exports = user;
