const Marionette = require('backbone.marionette');
const simpleStorage = require('simplestorage.js');
const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const Item = require('./infobarItem');
const Constants = require('../../Constants');
const Infos = require('../../collections/infos');
const serverTime = require('../../services/server-time');

let syncedLocalTime = null;

const setLocalTime = function fSetLocalTime() {
  serverTime.get().done((t) => {
    syncedLocalTime = t.getTime();
  });
};

setInterval(setLocalTime, 1000);

const isInInterval = function fIsInInterval(info) {
  const startdate = Date.parse(info.startdate);
  if (info.highlightExpire) {
    return syncedLocalTime >= startdate && syncedLocalTime <= Date.parse(info.highlightExpire);
  }
  return syncedLocalTime > startdate;
};

const isShowableInfo = function fIsShowableInfo(model) {
  const info = model.attributes;
  return isInInterval(info) && (info.highlight === 1) && (_.indexOf(this.shownInfos, info.id) === -1);
};

const InfobarView = Marionette.CollectionView.extend({
  childView:  Item,
  shownInfos: [],

  collectionEvents: {
    remove: 'onRemoveNotification',
  },

  onRemoveNotification(model, collection) {
    const shownIds = simpleStorage.get(Constants.infoStorageKey) || [];
    if (shownIds.length > 100) {
      shownIds.splice(0, shownIds.length - 100);
    }
    shownIds.push(model.id);
    simpleStorage.set(Constants.infoStorageKey, shownIds);
    this.shownInfos = shownIds;

    if (collection.length > 0) {
      collection.at(0).set('showing', true);
    }
    window.setTimeout(() => {
      if ($(document.body).find('.row.infobar').length === 0) {
        $(document.body).removeClass('info-visible');
      }
    }, 100);
  },

  initialize: function fInitialize() {
    this.collection = new Backbone.Collection();
    this.bindEntityEvents(this.collection, this.getOption('collectionEvents'));
    this.shownInfos = simpleStorage.get(Constants.infoStorageKey) || [];
    this.infos = new Infos();
    this.setInfos();
  },

  setInfos: function fSetInfos() {
    $.when(this.infos.fetch(), serverTime.get())
      .always(setLocalTime)
      .done(_.bind(function onDoneLoadingInfos() {
        this.collection.reset(
          _.filter(this.infos.models.map((info, index) => {
            const clone = info.clone();
            clone.set('showing', index === 0);
            return clone;
          }), _.bind(isShowableInfo, this))
        );
      }, this))
      .done(_.bind(function () {
        if (this.collection.length > 0) {
          $(document.body).addClass('info-visible');
        }
      }, this));
  },

  filter(model) {
    return model.get('showing');
  },
});

module.exports = InfobarView;
