'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const _ = require('underscore');
const i18n = require('i18next');
const Radio = require('backbone.radio');
const ErrorView = require('./components/msgBoxView');
const template = require('../templates/changepassword.hbs');

const navChannel = Radio.channel('controller');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/password/reset`,
  method: 'POST',
};

const fieldsToNotify = ['password', 'confirmpassword'];

const validatePassword = function fValidate(attributes, options) { // eslint-disable-line
  const validationReg = {
    password: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100})/,
  };

  let errors = [];

  if (_.has(attributes, 'password')) {
    if (!attributes.password.length) {
      errors.push('password');
    } else if (_.has(attributes, 'confirmpassword')) {
      if (attributes.confirmpassword !== attributes.password) {
        errors.push('password');
        errors.push('confirmpassword');
      }
    }
  }
  if (_.has(attributes, 'confirmpassword') && !attributes.confirmpassword.length) {
    errors.push('confirmpassword');
  }

  _.each(attributes, (value, field) => {
    if (field in validationReg) {
      if (!validationReg[field].test(value)) {
        errors.push(field);
      }
    }
  });
  errors = _.uniq(errors);
  if (errors.length) {
    if (options.error instanceof Function) {
      options.error(errors);
    }
    return errors;
  }
};

const ChangePasswordView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    password:             '#password',
    confirmpassword:      '#confirmpassword',
    pass_popover:         '#pass_popover',
    confirm_pass_popover: '#confirm_pass_popover',
    error:                '.error',
    closeA:               '.close',
    cancel:               '#cancel',
    form_group:           '.form-group',
    sent_email:           '.sent-email',
  },

  events: {
    'input @ui.password':        'onFocusPassword',
    'focus @ui.password':        'onFocusPassword',
    'blur @ui.password':         'onBlurPassword',
    'focus @ui.confirmpassword': 'onFocusConfirmPassword',
    'blur @ui.confirmpassword':  'onBlurConfirmPassword',
    'submit form':               'handleSubmit',
    'click @ui.cancel':          'onCancel',
  },

  initialize: function fInitialize(args) {
    this.model = args.model;
    this.model.set({
      passwordRules: this.passwordRules,
    });
  },

  onFocusPassword: function fOnFocusPassword() {
    if (this.ui.password.parent().hasClass('has-error')
       && !this.ui.pass_popover.attr('aria-describedby')) {
      this.ui.pass_popover.popover('show');
    }
  },

  onBlurPassword: function fOnFocusPassword() {
    this.ui.pass_popover.popover('hide');
  },

  onFocusConfirmPassword: function fOnFocusConfirmPassword() {
    if (this.ui.confirmpassword.parent().hasClass('has-error')
       && !this.ui.confirm_pass_popover.attr('aria-describedby')) {
      this.ui.confirm_pass_popover.popover('show');
    }
  },

  onBlurConfirmPassword: function fOnFocusConfirmPassword() {
    this.ui.confirm_pass_popover.popover('hide');
  },

  onCancel: function fOnCancel(event) {
    event.preventDefault();
    navChannel.trigger('USERNAME');
  },

  passwordRules: function fPasswordRules() {
    return [
      i18n.t('common:password-rules-uppercase'),
      i18n.t('common:password-rules-lowercase'),
      i18n.t('common:password-rules-numerical'),
      i18n.t('common:password-rules-minchars'),
      i18n.t('common:password-rules-confirm-match'),
    ];
  },

  setInputError: function fSetInputLabel(el) {
    el.each((index, elem) => {
      const $el = $(elem);
      const $elParent = $el.parent().parent();

      $elParent.find('.glyphicon').removeClass('glyphicon-ok hidden glyphicon-info-sign green blue').addClass('glyphicon-remove red');
      $el.parents('.input-icon').find('.has_tooltip').popover({ trigger: 'hover' });
      $el.parent().addClass('has-error');
    });
  },

  handleSubmit: function fHandleSubmit(event) {
    event.preventDefault();
    this.model.set({
      password:        this.ui.password.val(),
      confirmpassword: this.ui.confirmpassword.val(),
    });

    const data = {};

    const modelAttributes = _.pick(this.model.attributes, fieldsToNotify);

    _.each(modelAttributes, (element, field) => {
      data[field] = element;
    }, this);

    const validateForm = validatePassword(data, { errors: [] });

    if (validateForm) {
      _.each(fieldsToNotify, function fieldsIterator(fieldName) {
        if (_.indexOf(validateForm, fieldName) !== -1) {
          this.setInputError(this.$el.find(`#${fieldName}`));
        }
      }, this);
    } else {
      $.ajax({
        url:    ajaxOptions.url,
        method: ajaxOptions.method,
        data:   {
          token:           this.model.get('token'),
          password:        this.model.get('password'),
          confirmPassword: this.model.get('confirmpassword'),
        },
      })
        .done(() => {
          this.ui.sent_email.removeClass('sent-email');
          navChannel.trigger('CHANGE_EMAIL_SUCCESS');
        })
        .fail((error) => {
          console.error('Error happened', error);
          this.errorView.show(i18n.t('common:unknown-error'));
        });
    }
  },
  onRender: function fOnRender() {
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
    this.$el.find('.has-tooltip').popover({ trigger: 'hover' });
    setTimeout(() => this.ui.password.focus(), 1); // Doesn't work without timeout, cba with proper fix
  },
});

module.exports = ChangePasswordView;
