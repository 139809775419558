var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"invite-notif-container\" style=\"display: flex\">\n    <div style=\"margin-right: 20px;\">\n        <i style=\"height: 50px; width: 50px\" class=\"netop-info\"></i>\n    </div>\n    <div>\n        <p style=\"font-size: 20px\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:invite-not-available",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":73}}}))
    + "</p>\n        <p>"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:contact-account-admin",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":50}}}))
    + "</p>\n    </div>\n</div>";
},"useData":true});