'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const _ = require('underscore');
const WarningView = require('./components/warningView');
const ErrorView = require('./components/msgBoxView');
const template = require('../templates/ldap-auth.hbs');

const navChannel = Radio.channel('controller');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/nextStep`,
  method: 'POST',
};

const PasswordView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    password:    '#password',
    error:       '.error',
    input_error: '.input-error',
    closeA:      '.close',
    cancel:      '#cancel',
    form_group:  '.form-group',
  },
  events: {
    'submit form':              'handleSubmit',
    'click @ui.closeA':         'handleCloseAlert',
    'click @ui.cancel':         'handleClickCancel',
    'click @ui.forgotPassword': 'handleClickForgotPassword',
    'input @ui.password':       'onFocusPassword',
    'focus @ui.password':       'onFocusPassword',
    'blur @ui.password':        'onBlurPassword',
  },

  handleClickCancel: function fHandleClickCancel(event) {
    event.preventDefault();
    this.model.clear();
    navChannel.trigger('USERNAME');
  },

  onFocusPassword: function fOnFocusPassword() {
  },

  onBlurPassword: function fOnBlurPassword() {
  },

  handleSubmit: function fHandleSubmit(e) {
    e.preventDefault();

    const password = this.ui.password.val();
    if (!password) {
      this.errorView.show(i18n.t('common:mandatory-field'));
      this.ui.password.parent().addClass('has-error');
      this.ui.password.focus();
      return;
    }

    this.model.set({
      password,
    });

    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        loginSessionId: this.model.get('loginSessionId'),
        currentStep:    this.model.get('nextStep'),
        input:          this.model.get('password'),
      },
    })
      .done((body) => {
        if (body.nextStep === 'ERROR') {
          this.model.set(_.extend({}, body, (body.options || {})));
          navChannel.trigger(this.model.get('nextStep'));
          return;
        }

        if (body.options.error) {
          this.ui.password.parent().addClass('has-error');
          this.ui.password.val('');
          this.ui.password.focus();

          switch (body.options.error) {
            case '30007':
            case '61004':
              this.warningView.show(i18n.t('common:credential-error'));
              this.errorView.hide();
              break;
            default:
              console.error(`Error happened (error code: ${body.options.error})`);
              this.errorView.show(i18n.t('common:unknown-error'));
              this.warningView.hide();
              break;
          }
          return;
        }

        this.model.set({
          loginSessionId: body.loginSessionId,
          nextStep:       body.nextStep,
          options:        body.options || {},
        });

        if (body.options.token) {
          this.model.set('token', body.options.token);
        }

        navChannel.trigger(this.model.get('nextStep'));
      })
      .fail((error) => {
        console.error(`Error happened (error code: ${error})`);
        this.errorView.show(i18n.t('common:unknown-error'));
        this.warningView.hide();
      });
  },
  onRender: function fOnRender() {
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
    this.warningView = new WarningView({
      el: this.ui.input_error,
    });
    setTimeout(() => this.ui.password.focus(), 1); // Doesn't work without timeout, cba with proper fix
  },
});

module.exports = PasswordView;
