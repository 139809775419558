var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "- "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + " <br/>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"account-setup-container\">\n    <div class=\"account-setup-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:account-setup-title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":74}}}))
    + "</div>\n    <hr class=\"account-setup-hr\">\n    <div style=\"display: flex\">\n        <div class=\"setup-section border-right\">\n            <img id=\"img-invitation\" class=\"\" src=\"" + require("../../images/Invitation.png") + "\" alt=\"NRC Invitation\" />\n            <p style=\"font-size: 18px;\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:welcome",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":65}}}))
    + "</p>\n            <p style=\"font-size: 16px;\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:necessary-details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":75}}}))
    + "</p>\n        </div>\n        <div class=\"setup-section\">\n            <form>\n                <div class=\"input-error\"></div>\n                <br>\n                <div class=\"input-icon\">\n                    <label for=\"firstname\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:firstname-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":76}}}))
    + "</label>\n                    <input type=\"text\" class=\"input\" id=\"firstname\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"firstname") : depth0), depth0))
    + "\">\n                    <span\n                        class=\"setup-info-icon new-form glyphicon glyphicon-info-sign has-tooltip\"\n                        data-toggle=\"popover\" data-html=\"true\" data-placement=\"auto top\" title='"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:first-rule",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":96},"end":{"line":19,"column":125}}}))
    + ":' data-content=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"nameRules") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":142},"end":{"line":19,"column":186}}})) != null ? stack1 : "")
    + "\">\n                    </span>\n                </div>\n                <div class=\"input-icon\">\n                    <label for=\"lastname\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:lastname-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":42},"end":{"line":23,"column":74}}}))
    + "</label>\n                    <input type=\"text\" class=\"input\" id=\"lastname\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"lastname") : depth0), depth0))
    + "\">\n                    <span\n                        class=\"setup-info-icon new-form glyphicon glyphicon-info-sign has-tooltip\"\n                        data-toggle=\"popover\" data-html=\"true\" data-placement=\"auto top\" title='"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:last-rule",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":96},"end":{"line":27,"column":124}}}))
    + ":' data-content=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"nameRules") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":141},"end":{"line":27,"column":185}}})) != null ? stack1 : "")
    + "\">\n                    </span>\n                </div>\n                <div class=\"input-icon\">\n                    <label for=\"password\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:password-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":42},"end":{"line":31,"column":74}}}))
    + "</label>\n                    <input type=\"password\" class=\"input\" id=\"password\"><span id=\"showPwd\"></span>\n                    <span id=\"pass_popover\"\n                        class=\"setup-info-icon new-form glyphicon glyphicon-info-sign has-tooltip\"\n                        data-toggle=\"popover\" data-html=\"true\" data-placement=\"auto top\" title='"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:password-rules",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":96},"end":{"line":35,"column":129}}}))
    + ":' data-content=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"passwordRules") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":146},"end":{"line":35,"column":194}}})) != null ? stack1 : "")
    + "\">\n                    </span>\n                    <div class=\"password-strength-container\"></div>\n                </div>\n                <div class=\"input-icon\">\n                    <label for=\"confirmpassword\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:confirm-password-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":49},"end":{"line":40,"column":89}}}))
    + "</label>\n                    <input type=\"password\" class=\"input\" id=\"confirmpassword\"><span id=\"showConfirmPwd\"></span>\n                    <span id=\"confirm_pass_popover\"\n                        class=\"setup-info-icon new-form glyphicon glyphicon-info-sign has-tooltip\"\n                        data-toggle=\"popover\" data-html=\"true\" data-placement=\"auto top\" title='"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:password-rules",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":44,"column":96},"end":{"line":44,"column":129}}}))
    + ":' data-content=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"passwordRules") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":146},"end":{"line":44,"column":194}}})) != null ? stack1 : "")
    + "\">\n                    </span>\n                </div>\n                <button type=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:save-account",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":55},"end":{"line":47,"column":85}}}))
    + "</button>\n            </form>\n        <div>\n    </div>\n</div>";
},"useData":true});