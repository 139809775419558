'use strict';

const Backbone = require('backbone');
const i18n = require('i18next');
const { passwordStrength } = require('check-password-strength');
const Marionette = require('backbone.marionette');
const template = require('../../templates/pwdStrengthView.hbs');

const PwdStrengthModel = Backbone.Model.extend({
  defaults: {
    message: '',
  },
});

const PwdStrengthView = Marionette.ItemView.extend({
  template,
  ui: {
  },
  events: {
  },

  target:     null,
  initialize: function fInitialize() {
    this.model = new PwdStrengthModel({ message: '' });
    this.listenTo(this.model, 'change', this.render);
    this.evaluate();
  },

  evaluate: function fEvaluate(pwd) {
    const key = `password-strength-${pwd ? passwordStrength(pwd).id : 'missing'}`;
    this.model.set({ message: i18n.t(`common:${key}`) });
    this.$el.removeClass([
      'password-strength-missing',
      'password-strength-0',
      'password-strength-1',
      'password-strength-2',
      'password-strength-3',
    ]).addClass(key);
  },
});

module.exports = PwdStrengthView;
