'use strict';

const Backbone = require('backbone');
const i18n = require('i18next');

const routingChannel = require('backbone.radio').channel('controller');
const parseUrl = require('url-parse');
const _ = require('underscore');
const simpleStorage = require('simplestorage.js');

const AuthModel = require('../models/user');
const AppLayout = require('./layout');
const UsernameView = require('../views/usernameView');
const PasswordView = require('../views/passwordView');
const LdapAuthView = require('../views/ldapAuthView');
const MFAEmailView = require('../views/mfaemailView');
const MFAOTCView = require('../views/mfaonetimeView');
const ForgotPasswordView = require('../views/forgotPasswordView');
const ForgotPasswordSuccessView = require('../views/forgotPasswordSuccessView');
const ChangePasswordView = require('../views/changePasswordView');
const ChangePasswordCancelView = require('../views/changePasswordCancelView');
const ChangePasswordSuccessView = require('../views/changePasswordSuccessView');
const SuccessView = require('../views/successView');
const AdfsView = require('../views/adfsView');
const AdfsSuccessView = require('../views/adfsSuccessView');
const TrialVerifyView = require('../views/trialVerifyView');
const TrialSetupView = require('../views/trialSetupView');
const NotificationsView = require('../views/notificationsView');
const OAuthAuthorizeView = require('../views/oAuthAuthorizeView');
const OAuthEndView = require('../views/oAuthEndView');
const AccountSetupView = require('../views/accountSetup');
const EmailRegistrationSuccess = require('../views/emailRegistrationSuccess');
const InviteNotAvailable = require('../views/inviteNotAvailable');
const utils = require('../utils');
const Constants = require('../Constants');
const StopError = require('../views/stopError');

const resumableSessionStates = ['SUCCESS', 'MFA_EMAIL', 'MFA_OTC', 'ADFS_SUCCESS', 'ERROR'];

function initializeLayout() {
  const LayoutModel = Backbone.Model.extend({
    defaults: {
      copyright: i18n.t('common:copyright', { currentYear: (new Date()).getFullYear() }),
    },
  });
  const model = new LayoutModel();
  const layout = new AppLayout({ model });
  layout.render();
  layout.getRegion('notification').show(new NotificationsView(), {});
  return layout;
}

function initializeListeners({ layout, channel }) {
  const authModel = new AuthModel();
  channel.on('USERNAME', () => {
    layout.getRegion('middle').show(new UsernameView({ model: authModel }), {});
    Backbone.history.navigate('login/');
  });

  channel.on('FORGOT_PASSWORD', (options) => {
    layout.getRegion('middle').show(new ForgotPasswordView({ model: authModel, options }), {});
    Backbone.history.navigate('forgot-password/');
  });

  channel.on('FORGOT_PASSWORD_SUCCESS', () => {
    layout.getRegion('middle').show(new ForgotPasswordSuccessView({ model: authModel }), {});
    Backbone.history.navigate('forgot-password/');
  });

  channel.on('AUTH_PORTAL', (options) => {
    layout.getRegion('middle').show(new PasswordView({ model: authModel, options }), {});
    Backbone.history.navigate('password/');
  });

  channel.on('MFA_EMAIL', () => {
    layout.getRegion('middle').show(new MFAEmailView({ model: authModel }), {});
    Backbone.history.navigate('login/mfa/email/');
  });

  channel.on('MFA_OTC', () => {
    layout.getRegion('middle').show(new MFAOTCView({ model: authModel }), {});
    Backbone.history.navigate('login/mfa/one-time-code/');
  });

  channel.on('SUCCESS', () => {
    layout.getRegion('middle').show(new SuccessView({ model: authModel }), {});
    Backbone.history.navigate('success/');
  });

  channel.on('TRIAL_VERIFY', (token) => {
    authModel.attributes.token = token;
    layout.getRegion('middle').show(new TrialVerifyView({ model: authModel }), {});
  });

  channel.on('TRIAL_SETUP', (token) => {
    authModel.set({ setupToken: token });
    $.ajax({
      url:    `${NETOP_CONFIG.SECURE_API}/trial-setup/${token}`,
      method: 'GET',
    }).done(({ firstname, lastname }) => {
      authModel.set({ firstname, lastname });
      layout.getRegion('middle').show(new TrialSetupView({ model: authModel }), {});
      Backbone.history.navigate(`trial-setup/${token}`);
    }).fail((response) => {
      authModel.clear();
      const errorCode = utils.getErrorCode(response);
      if (errorCode === '6804') {
        authModel.set({ error: errorCode });
        channel.trigger('ERROR');
        return;
      }
      const message = utils.mapErrorCodeToMessage(errorCode, 'error:unknown-trial');

      channel.trigger('STOP_ERROR', {
        title: i18n.t('common:error-trial-title'),
        message,
      });
    });
  });

  channel.on('CHANGE_EMAIL', (token) => {
    authModel.attributes.token = token;
    layout.getRegion('middle').show(new ChangePasswordView({ model: authModel }), {});
  });

  channel.on('CHANGE_EMAIL_CANCEL', (resetPasswordCode) => {
    authModel.set('resetPasswordCode', resetPasswordCode);
    layout.getRegion('middle').show(new ChangePasswordCancelView({ model: authModel }), {});
  });

  channel.on('CHANGE_EMAIL_SUCCESS', () => {
    layout.getRegion('middle').show(new ChangePasswordSuccessView({ model: authModel }), {});
  });

  channel.on('ADFS_START', () => {
    layout.getRegion('middle').show(new AdfsView({ model: authModel }), {});
    Backbone.history.navigate('adfs/start');
  });

  channel.on('AZURE_AD_START', () => {
    layout.getRegion('middle').show(new AdfsView({ model: authModel }), {});
    Backbone.history.navigate('azure-ad/start');
  });

  channel.on('ADFS_SUCCESS', () => {
    layout.getRegion('middle').show(new AdfsSuccessView({ model: authModel }), {});
    Backbone.history.navigate('adfs/success');
  });

  channel.on('ACCOUNT_SETUP', (inviteKey) => {
    // invite user account setup
    $.ajax({
      url:    `${NETOP_CONFIG.SECURE_API}/invite/${inviteKey}`,
      method: 'GET',
    }).done((invite) => {
      const { invitedBy, inviteLogin, username } = invite.user;
      if (inviteLogin) {
        // invite in secondary account; ask the user to login
        // the setup will be done after the login (successView)
        authModel.set({
          inviteLogin,
          inviteKey,
          invitedBy,
          username,
        });
        channel.trigger('USERNAME');
        return;
      }

      layout.getRegion('middle').show(new AccountSetupView({ model: authModel, inviteKey, invitedBy }), {});
      Backbone.history.navigate(`setup/${inviteKey}`);
    }).fail((e) => {
      const { responseJSON: error = {} } = e;
      if (error.error_code === '11207') {
        channel.trigger('EMAIL_REGISTERED');
      } else {
        channel.trigger('INVITE_NOT_AVAILABLE');
      }
    });
  });

  channel.on('MFA_EMAIL_SWITCH_ACCOUNT_START', (loginSessionId, returnPage) => {
    authModel.set({
      loginSessionId,
      nextStep:      'MFA_EMAIL_SWITCH_ACCOUNT_START',
      attempt:       0,
      switchAccount: true,
      nativeClient:  (simpleStorage.get(Constants.nativeClient) === true),
      returnPage:    returnPage || null,
    });
    const mfaEmailView = new MFAEmailView({ model: authModel });
    layout.getRegion('middle').show(mfaEmailView, {});
    Backbone.history.navigate('login/mfa/email/');

    $.ajax({
      url:    `${NETOP_CONFIG.SECURE_API}/nextStep`,
      method: 'POST',
      data:   {
        loginSessionId: authModel.get('loginSessionId'),
        input:          authModel.get('loginSessionId'),
        currentStep:    authModel.get('nextStep'),
      },
    })
      .done((body) => {
        if (body.nextStep === 'ERROR') {
          authModel.set(_.extend({}, body, (body.options || {})));
          if (authModel.get('error')) {
            mfaEmailView.errorView.show(utils.mapErrorCodeToMessage(authModel.get('error')));
            mfaEmailView.warningView.hide();
          }
          return;
        }

        const result = {
          loginSessionId: body.loginSessionId,
          nextStep:       body.nextStep,
          options:        body.options || {},
          username:       (body && body.options && body.options.username) || '',
        };
        authModel.set(result);
        routingChannel.trigger(authModel.get('nextStep'));
      })
      .fail((error) => {
        console.error(`Error happened (error code: ${error})`);
        mfaEmailView.errorView.show(i18n.t('common:unknown-error'));
        mfaEmailView.warningView.hide();
      });
  });

  channel.on('MFA_EMAIL_SWITCH_ACCOUNT', () => {
    layout.getRegion('middle').show(new MFAEmailView({ model: authModel }), {});
    Backbone.history.navigate('login/mfa/email/');
  });

  channel.on('EMAIL_REGISTERED', () => {
    layout.getRegion('middle').show(new EmailRegistrationSuccess({ model: authModel }), {});
  });

  channel.on('INVITE_NOT_AVAILABLE', () => {
    layout.getRegion('middle').show(new InviteNotAvailable({ model: authModel }), {});
  });

  channel.on('RESUME_SESSION', (query) => {
    let session = null;
    authModel.clear();
    try {
      session = JSON.parse(window.atob(query.session));
      if (resumableSessionStates.indexOf(session.nextStep) === -1) {
        throw new Error('Invalid session state');
      }
    } catch (error) {
      session = {
        nextStep: 'ERROR',
        error:    777,
      };
    }
    session = _.extend(session, (session.options || {}));
    if (session.options && session.options.inviteKey) {
      // resuming from an external login with an inviteKey
      session = _.extend(session, {
        inviteLogin: true,
        inviteKey:   session.options.inviteKey,
      });
    }
    authModel.set(session);
    channel.trigger(session.nextStep);
  });

  channel.on('LDAP_AUTH', () => {
    layout.getRegion('middle').show(new LdapAuthView({ model: authModel }), {});
    Backbone.history.navigate('ldap/password/');
  });

  channel.on('ERROR', () => {
    authModel.set('nextStep', 'USERNAME');
    channel.trigger(authModel.get('nextStep'));
  });

  channel.on('STOP_ERROR', (err) => {
    const stopError = _.extend({
      title:   'title',
      message: 'message',
    }, err || {});
    layout.getRegion('middle').show(new StopError({ model: new Backbone.Model(stopError) }), {});
  });

  channel.on('OAUTH_AUTHORIZE_INIT', (identifier) => {
    authModel.set('authorize', identifier);
    routingChannel.trigger('USERNAME');
  });

  channel.on('OAUTH_AUTHORIZE', () => {
    // authModel.set({
    //   options: {
    //     name:  'Freshdesk',
    //     scope: 'ondemand.create,ondemand.start',
    //     url:   'https://freshdesk.com',
    //   }
    // });

    layout.getRegion('middle').show(new OAuthAuthorizeView({ model: authModel }), {});
    Backbone.history.navigate('oauth/authorize');
  });

  channel.on('OAUTH_END', () => {
    layout.getRegion('middle').show(new OAuthEndView({ model: authModel }), {});
    Backbone.history.navigate('oauth/end');
  });
}

function Controller({ initialRoutes, returnPage }) {
  const parsedUrl = parseUrl(window.location.href, true);
  const rootUrl = parsedUrl.pathname.replace(/^\//, '').split('/');
  const layout = initializeLayout();

  $(window).on('popstate', () => {
    routingChannel.trigger('USERNAME');
    return false;
  });
  initializeListeners({
    layout,
    channel: routingChannel,
  });

  switch (rootUrl[0]) {
    case 'setup':
      routingChannel.trigger(initialRoutes.accountSetup, rootUrl[1]);
      break;
    case 'resume':
      routingChannel.trigger(initialRoutes.resumeSession, parsedUrl.query);
      break;
    case 'password':
      if (rootUrl[1] === 'reset') {
        routingChannel.trigger(initialRoutes.changeEmail, rootUrl[2]);
      } else if (rootUrl[1] === 'cancel') {
        routingChannel.trigger(initialRoutes.changeEmailCancel, rootUrl[2]);
      } else {
        routingChannel.trigger(initialRoutes.login);
      }
      break;
    case 'trial':
      if (rootUrl[1] === 'verify') {
        routingChannel.trigger(initialRoutes.trialVerify, rootUrl[2]);
      }
      if (rootUrl[1] === 'setup') {
        routingChannel.trigger(initialRoutes.trialSetup, rootUrl[2]);
      }
      break;
    case 'oauth':
      if (rootUrl[1] === 'initialize') {
        routingChannel.trigger(initialRoutes.oAuthAuthorizeInit, rootUrl[2]);
        // } else if ('authorize' === rootUrl[1]) {
        //   routingChannel.trigger('OAUTH_AUTHORIZE');
      } else {
        routingChannel.trigger(initialRoutes.login);
      }
      break;
    case 'mfa-email-switch-account':
      routingChannel.trigger(initialRoutes.mfaEmailSwitchAccountStart, rootUrl[1], returnPage);
      break;
    case 'login':
    default:
      routingChannel.trigger(initialRoutes.login);
  }
}

module.exports = Controller;
