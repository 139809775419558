const Marionette = require('backbone.marionette');
const simpleStorage = require('simplestorage.js');
const notificationsTemplate = require('../templates/notifications.hbs');
const Constants = require('../Constants');
const InfoBar = require('./components/infobar');

const NotificationsView = Marionette.LayoutView.extend({
  template: notificationsTemplate,
  regions:  {
    current: '#notification-current',
  },
  onRender: function fInitialize() {
    this.renderInfoBar();
  },

  renderInfoBar: function fRenderInfoBar() {
    if (simpleStorage.get(Constants.nativeClient) === true) {
      this.getRegion('current').show(new InfoBar());
    }
  },
});

module.exports = NotificationsView;
