'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const ErrorView = require('./components/msgBoxView');
const template = require('../templates/oAuthAuthorizeView.hbs');

const navChannel = Radio.channel('controller');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/nextStep`,
  method: 'POST',
};

const OAuthAuthorizeView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    deny:  '#deny',
    allow: '#allow',
    error: '.error',
  },
  events: {
    'click @ui.allow': 'handleClickAllow',
    'click @ui.deny':  'handleClickDeny',
  },

  initialize: function fInitialize({ model }) {
    this.model = model;

    const readableScope = (this.model.get('options').scope || '')
      .split(',')
      .map((part) => i18n.t(`scope:${part.replace('.', '|').trim()}`));

    this.model.set('readableScope', readableScope);
  },

  handleClickAllow: function fHandleClickCancel() {
    this.model.set('authorized', true);
    this.handleAuthorization();
  },
  handleClickDeny: function fHandleClickDeny() {
    this.model.set('authorized', false);
    this.handleAuthorization();
  },
  handleAuthorization: function fHandleAuthorization() {
    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        loginSessionId: this.model.get('loginSessionId'),
        currentStep:    this.model.get('nextStep'),
        input:          this.model.get('authorized'),
      },
    })
      .done((body) => {
        this.model.set({
          loginSessionId: body.loginSessionId,
          nextStep:       body.nextStep,
          options:        (body.options || {}),
        });
        navChannel.trigger(this.model.get('nextStep'));
      })
      .fail((error) => {
        console.error(`Error happened (error code: ${error})`);
        this.errorView.show(i18n.t('common:unknown-error'));
      });
  },
  onRender: function fOnRender() {
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
  },
});

module.exports = OAuthAuthorizeView;
