'use strict';

const Backbone = require('backbone');
const Marionette = require('backbone.marionette');
const template = require('../../templates/captcha.hbs');
const guestCaptcha = require('./guestCaptcha');

// to disable captcha rename the CAPTCHA_KEY on the env
// and set LOGIN_CAPTCHA_ENABLED = false in populator

const CaptchaModel = Backbone.Model.extend({
  defaults: {
    captchaLoaded: window.captchaLoaded, // eslint-disable-line
  },
});

const CaptchaView = Marionette.ItemView.extend({
  template,
  token: null,

  initialize: function fInitialize() {
    this.model = new CaptchaModel({ captchaLoaded: window.captchaLoaded });
    this.GUEST_CAPTCHA_NOT_PRESENT_ERROR = new Error('captcha not present');
    this.GUEST_CAPTCHA_NOT_PRESENT_ERROR.name = 'GUEST_CAPTCHA_NOT_PRESENT';
    this.hasCaptcha = false;
  },

  onBeforeDestroy: function fOnBeforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  onCaptchaResponse: function fCaptchaResponse(result) {
    this.token = result;
    // prevents recaptcha error - Uncaught TypeError: Cannot read property 'style' of null
    grecaptcha.reset(); // eslint-disable-line
    this.trigger('captcha:response');
  },
  onCaptchaError: function fCaptchaError(err) {
    this.token = null;
    this.trigger('captcha:error', err);
  },
  onCaptchaExpired: function fCaptchaExpired() {
    this.token = null;
    this.trigger('captcha:expired');
  },

  reset: function fReset() {
    if (!this.hasCaptcha) {
      return;
    }
    this.token = null;
    grecaptcha.reset(); // eslint-disable-line
  },

  execute: function fExecute() {
    if (!this.hasCaptcha) {
      return;
    }
    this.token = null;
    this._observeDOM(); // eslint-disable-line
    return grecaptcha.execute(); // eslint-disable-line
  },

  // private
  _observeDOM: function fObserveDOM() {
    if (this.observer) {
      this.observer.disconnect();
    }

    // watch for the captcha divs added to the body
    // - a parent div added to the body
    //   - one of its direct children has an iframe from google.com/recaptcha/api2
    this.observer = new MutationObserver((recs) => {
      recs.forEach((rec) => {
        if (rec.type === 'childList') {
          rec.addedNodes.forEach((node) => {
            const captchaRoot$ = $(node); // direct child of body
            const ch$ = captchaRoot$.children(); // it's children
            ch$.each((i, el) => {
              const $ifr = $(el).children('iframe');
              if ($ifr.length) {
                $ifr.each((index, ifr) => {
                  const src = $(ifr).attr('src') || '';
                  if (src.toLowerCase().indexOf('recaptcha') > 0) {
                    // google recaptcha frame found; terminate the observer and create another
                    // to watch for the attributes changes of the captcha root div
                    // when the challange is displayed its css visibility is 'visible', otherwise it's 'hidden'
                    this.observer.disconnect();
                    this.observer = null;
                    this.observer = new MutationObserver(() => {
                      this.trigger(captchaRoot$.css('visibility') === 'visible' ? 'captcha:challange:visible' : 'captcha:challange:hidden');
                    });
                    this.observer.observe(captchaRoot$[0], { attributes: true });
                  }
                });
              }
            });
          });
        }
      });
    });
    // observe body children changes
    this.observer.observe(document.body, { childList: true });
  },

  onRender: function fOnRender() {
    this._observeDOM(); // eslint-disable-line
    setTimeout(() => {
      try {
        if (guestCaptcha.guestCaptchaNotPresent()) {
          throw guestCaptcha.GUEST_CAPTCHA_NOT_PRESENT_ERROR;
        }
        grecaptcha.render('g-recaptcha', { // eslint-disable-line
          sitekey:            NETOP_CONFIG.CAPTCHA_KEY,
          size:               'invisible',
          callback:           this.onCaptchaResponse.bind(this),
          'error-callback':   this.onCaptchaError.bind(this),
          'expired-callback': this.onCaptchaExpired.bind(this),
        });
        this.hasCaptcha = true;
        this.trigger('captcha:rendered');
      } catch (err) {
        // console.log(err);
        this.onCaptchaError(err);
      }
    }, 1);
  },
});

module.exports = CaptchaView;
