const Marionette = require('backbone.marionette');
const layoutTemplate = require('../../templates/infobar.hbs');

const InfobarItemView = Marionette.ItemView.extend({
  template:  layoutTemplate,
  className: 'row infobar',

  events: {
    'click .ui-control-dismiss': 'onClickDismiss',
  },

  templateHelpers() {
    const displayDescription = this.model.get('summary') === 'Downtime Notification';
    return {
      displayDescription,
    };
  },

  onClickDismiss: function fOnClickDismiss(event) {
    event.preventDefault();
    this.model.collection.remove(this.model);
  },
});

module.exports = InfobarItemView;
