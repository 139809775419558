'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const _ = require('underscore');
const WarningView = require('./components/warningView');
const ErrorView = require('./components/msgBoxView');
const template = require('../templates/mfaemail.hbs');

const navChannel = Radio.channel('controller');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/nextStep`,
  method: 'POST',
};

const MFAEmailView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    mfacode:             '#mfacode',
    error:               '.error',
    input_error:         '.input-error',
    closeA:              '.close',
    cancel:              '#cancel',
    form_group:          '.form-group',
    'use-recovery-code': '#use-recovery-code',
  },
  events: {
    'submit form':                 'handleSubmit',
    'click @ui.closeA':            'handleCloseAlert',
    'click @ui.cancel':            'handleClickCancel',
    'input @ui.mfacode':           'onFocusMFACode',
    'focus @ui.mfacode':           'onFocusMFACode',
    'blur @ui.mfacode':            'onBlurMFACode',
    'click @ui.use-recovery-code': 'onUseRecoveryCode',
  },
  modelEvents: {
    change: 'render',
  },

  handleCloseAlert: function fHandleCloseAlert(event) {
    event.preventDefault();
    this.ui.error.hide();
  },

  onUseRecoveryCode: function fOnUseRecoveryCode(event) {
    event.preventDefault();
    navChannel.trigger('MFA_OTC');
  },

  onFocusMFACode: function fOnFocusMFACode() {
  },

  onBlurMFACode: function fOnBlurMFACode() {
  },

  handleClickCancel: function fHandleClickCancel(e) {
    e.preventDefault();

    navChannel.trigger('USERNAME');
  },

  handleSubmit: function fHandleSubmit(e) {
    e.preventDefault();

    const mfacode = this.ui.mfacode.val().trim();
    if (!mfacode) {
      this.warningView.show(i18n.t('common:mandatory-field'));
      this.errorView.hide();
      this.ui.mfacode.parent().addClass('has-error');
      this.ui.mfacode.focus();
      return;
    }

    this.model.set({
      mfacode,
    });

    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        loginSessionId: this.model.get('loginSessionId'),
        currentStep:    this.model.get('nextStep'),
        input:          this.model.get('mfacode'),
      },
    })
      .done((body) => {
        if (body.nextStep === 'ERROR') {
          this.model.set(_.extend({}, body, (body.options || {})));
          navChannel.trigger(this.model.get('nextStep'));
          return;
        }
        if (body.options.error) {
          this.ui.mfacode.parent().addClass('has-error');
          this.ui.mfacode.val('');
          this.ui.mfacode.focus();

          switch (body.options.error) {
            case '30006':
              this.warningView.show(i18n.t('common:email-code-error'));
              this.errorView.hide();
              break;
            default:
              console.error(`Error happened (error code: ${body.options.error})`);
              this.errorView.show(i18n.t('common:unknown-error'));
              this.warningView.hide();
              break;
          }
          return;
        }

        this.model.set({
          loginSessionId: body.loginSessionId,
          nextStep:       body.nextStep,
        });

        if (body.options.token) {
          this.model.set('token', body.options.token);
        }

        navChannel.trigger(this.model.get('nextStep'));
      })
      .fail((error) => {
        console.error(`Error happened (error code: ${error})`);
        this.errorView.show(i18n.t('common:unknown-error'));
        this.warningView.hide();
      });
  },

  onRender: function fOnRender() {
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
    this.warningView = new WarningView({
      el: this.ui.input_error,
    });
    setTimeout(() => {
      try {
        this.ui.mfacode.focus();
      } catch (e) {
        //
      }
    }, 1); // Doesn't work without timeout, cba with proper fix
  },
});

module.exports = MFAEmailView;
