'use strict';

const Backbone = require('backbone');
const Marionette = require('backbone.marionette');
const template = require('../../templates/warning.hbs');

const WarningModel = Backbone.Model.extend({
  defaults: {
    message: '',
  },
});

const WarningView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    alert: '.alert',
  },

  initialize: function fInitialize() {
    this.model = new WarningModel({ message: 'Inititalize message' });
  },

  _handleCloseAlert: function fHandleCloseAlert(event) {
    event.preventDefault();
    this.$el.hide();
  },

  hide: function fHide() {
    this.$el.hide();
  },

  show: function fShow(message) {
    this.model.set({ message });
    this.render();
    this.$el.show();
  },
});

module.exports = WarningView;
