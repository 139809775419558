var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"invite-login\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:invitation-text",{"name":"i18n","hash":{"lastName":((stack1 = (depth0 != null ? lookupProperty(depth0,"invitedBy") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1),"firstName":((stack1 = (depth0 != null ? lookupProperty(depth0,"invitedBy") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1)},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":123}}}))
    + "</p>\n        <p class=\"invite-login\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:invite-login",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":62}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"sub-message-container\">\n        <div class=\"sub-message hidden\"></div>\n        <div class=\"sub-message hidden\"></div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <div><div id=\"username-title\" class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":53},"end":{"line":2,"column":76}}}))
    + "</div></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inviteLogin") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "    <form>\n        <div class=\"error\"></div>\n        <div class=\"input-icon\">\n            <label id=\"username_label\" for=\"username\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:username-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":54},"end":{"line":15,"column":86}}}))
    + "</label>\n            <input type=\"text\" class=\"input\" id=\"username\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"username") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":59},"end":{"line":16,"column":102}}})) != null ? stack1 : "")
    + ">\n        </div>\n        <div class=\"input-error\"></div>\n        <button id=\"submit-btn\" type=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":63},"end":{"line":19,"column":85}}}))
    + "</button>\n    </form>\n    <div id=\"login_links\" class=\"links\">\n        <a class=\"link\" href=\""
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"url:trial",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":30},"end":{"line":22,"column":50}}}))
    + "\" target=\"_blank\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:sign-up",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":68},"end":{"line":22,"column":93}}}))
    + "</a>\n        <a class=\"link-sm\" href=\""
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"url:product",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":55}}}))
    + "\" target=\"_blank\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:about-product",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":73},"end":{"line":23,"column":104}}}))
    + "</a>\n    </div>\n    <div class=\"terms-and-privacy-container\">\n        <div>"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:policies-terms-text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":13},"end":{"line":26,"column":50}}}))
    + "<a class=\"link\" href=\""
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"url:policies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":72},"end":{"line":26,"column":95}}}))
    + "\" target=\"_blank\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:policies-terms-link-text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":113},"end":{"line":26,"column":155}}}))
    + "</a></div>\n    </div>\n</div>\n";
},"useData":true});