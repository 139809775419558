var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <div><div class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:forgot-password-title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":72}}}))
    + "</div></div>\n    <div class=\"center-block username\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</div>\n    <div>\n        <p>"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:forgot-password-success-text1",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":58}}}))
    + "</p>\n        <p>"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:forgot-password-success-text2",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":11},"end":{"line":6,"column":58}}}))
    + "</p>\n    </div>\n    <div class=\"form-group\">\n        <a href=\"#\" class=\"link-sm\" id=\"cancel\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:return-to-login",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":81}}}))
    + "</a>\n    </div>\n</div>";
},"useData":true});