'use strict';

const Marionette = require('backbone.marionette');
const Radio = require('backbone.radio');
const template = require('../templates/emailRegistered.hbs');

const navChannel = Radio.channel('controller');

const EmailRegistered = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    gotoLogin: '#goto-login-btn',
  },

  events: {
    'click @ui.gotoLogin': 'onGotoLogin',
  },

  onGotoLogin: function fOnGotoLogin(event) {
    event.preventDefault();
    navChannel.trigger('USERNAME');
  },
});

module.exports = EmailRegistered;
