'use strict';

const Marionette = require('backbone.marionette');
const template = require('../templates/success.hbs');

const View = Marionette.ItemView.extend({
  template,
  tagName:  'div',
  onRender: function fOnRender() {
    const redirectUrl = this.model.get('options').url;
    window.location.href = redirectUrl;
  },
});

module.exports = View;
