'use strict';

const simpleStorage = require('simplestorage.js');
const Constants = require('../../Constants');

const GUEST_CAPTCHA_NOT_PRESENT_ERROR = new Error('captcha not present');
GUEST_CAPTCHA_NOT_PRESENT_ERROR.name = 'GUEST_CAPTCHA_NOT_PRESENT';

module.exports = {
  GUEST_CAPTCHA_NOT_PRESENT_ERROR,
  guestCaptchaNotPresent: function fGuestCaptchaNotPresent() {
    return (typeof grecaptcha === 'undefined' && (simpleStorage.get(Constants.nativeClient) ? 1 : 0));
  },
};
