var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <div><div class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":56}}}))
    + "</div></div>\n    <div class=\"center-block username\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</div>\n    <form>\n        <div class=\"error\"></div>\n        <div class=\"input-icon\">\n            <label for=\"password\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:password-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":34},"end":{"line":7,"column":66}}}))
    + "</label>\n            <input type=\"password\" class=\"input\" id=\"password\">\n        </div>\n        <div class=\"input-error\" style=\"margin-bottom: 25px\"></div>\n        <button stype=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":70}}}))
    + "</button>\n    </form>\n    <div class=\"form-group\">\n        <a href=\"#\" class=\"link-sm\" id=\"cancel\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:change-acc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":48},"end":{"line":14,"column":76}}}))
    + "</a>\n    </div>\n</div>";
},"useData":true});