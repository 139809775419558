'use strict';

const Backbone = require('backbone');
const Marionette = require('backbone.marionette');
const template = require('../../templates/msg-box.hbs');

const ErrorModel = Backbone.Model.extend({
  defaults: {
    message: '',
  },
});

const ErrorView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    alert: '.error-box',
  },

  initialize: function fInitialize() {
    this.model = new ErrorModel({ message: 'Inititalize message' });
  },

  hide: function fHide() {
    this.$el.hide();
  },

  show: function fShow(message, msgType = '') {
    switch (msgType) {
      case 'success':
        this.model.set({ message });
        this.render();
        this.ui.alert.addClass('alert-success');
        this.$el.show();
        break;
      case '':
      default:
        this.model.set({ message });
        this.render();
        this.ui.alert.addClass('alert-danger');
        this.$el.show();
        break;
    }
  },
});

module.exports = ErrorView;
