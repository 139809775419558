'use strict';

const Backbone = require('backbone');
const ChangelogModel = require('../models/changelog');

const ChangelogCollection = Backbone.Collection.extend({
  url:     `${NETOP_CONFIG.PORTAL_API}/v4/rest/downtime`,
  model:   ChangelogModel,
  getName: function fGetName() {
    return 'changelog';
  },
});

module.exports = ChangelogCollection;
