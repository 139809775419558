'use strict';

const Backbone = require('backbone');

const ChangelogModel = Backbone.Model.extend({
  parse: function fParse(changelog) {
    let body = null;
    try {
      body = JSON.parse(changelog.description);
    } catch (e) {
      body = [changelog.description];
    }
    changelog.description = body; // eslint-disable-line no-param-reassign
    return changelog;
  },
});
module.exports = ChangelogModel;
