'use strict';

const Marionette = require('backbone.marionette');
const layoutTemplate = require('../templates/layout.hbs');

const layoutRegions = {
  header:       '#header',
  middle:       '#middle',
  footer:       '#footer',
  notification: '#notification-bar',
};

const AppLayoutView = Marionette.LayoutView.extend({
  el:         '#main-content',
  template:   layoutTemplate,
  regions:    layoutRegions,
  initialize: function fInitApp(args) { this.model = args.model; },
});

module.exports = AppLayoutView;
