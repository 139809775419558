'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const _ = require('underscore');

const navChannel = Radio.channel('controller');
const template = require('../templates/trial-verify.hbs');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/trialTokenAuthentication`,
  method: 'POST',
};

const View = Marionette.ItemView.extend({
  tagName:  'div',
  template,
  onRender: function fOnRender() {
    this.validateToken();
  },
  validateToken: function validateToken() {
    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        token: this.model.get('token'),
      },
    })
      .done((body) => {
        this.model.set(_.extend(
          {},
          body,
          (body.options || {}),
        ));
        navChannel.trigger(this.model.get('nextStep'));
      })
      .fail((xhr) => {
        console.error('Error happened', xhr.status, xhr.responseJSON);
        const { code } = (xhr.responseJSON || {});
        const session = {
          nextStep: 'ERROR',
          error:    code || 777,
        };
        this.model.set(session);
        navChannel.trigger(this.model.get('nextStep'));
      });
  },
});

module.exports = View;
