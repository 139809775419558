'use strict';

const _ = require('underscore');
const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const WarningView = require('./components/warningView');
const ErrorView = require('./components/msgBoxView');
const ShowPwdView = require('./components/showPwdView');
const PwdStrengthView = require('./components/pwdStrengthView');
const { nameRulesReg, nameRules, getErrorCode } = require('../utils');

const navChannel = Radio.channel('controller');
const template = require('../templates/accountSetup.hbs');

const fieldsToNotify = ['firstname', 'lastname', 'password', 'confirmpassword'];

const validateData = function fValidate(attributes, options) { // eslint-disable-line
  // const pwdReg = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100})/;
  const pwdReg = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/;
  const validationReg = {
    password:        pwdReg,
    confirmpassword: pwdReg,
  };

  let errors = [];

  if (_.has(attributes, 'firstname') && (!attributes.firstname.length || !nameRulesReg.test(attributes.firstname))) {
    errors.push('firstname');
    errors.push('emptyValue');// flag an empty value
  }

  if (_.has(attributes, 'lastname') && (!attributes.lastname.length || !nameRulesReg.test(attributes.lastname))) { // check if lastName field has value
    errors.push('lastname');
    errors.push('emptyValue'); // flag an empty value
  }

  if (_.has(attributes, 'password')) {
    if (!attributes.password.length) { // check if password field has value
      errors.push('password');
    } else if (_.has(attributes, 'confirmpassword')) {
      if (attributes.confirmpassword !== attributes.password) { // check if password and confirm password values are equal
        errors.push('password');
        errors.push('confirmpassword');
        errors.push('notMatched'); // flag a password mismatch
      }
    }
  }
  if (_.has(attributes, 'confirmpassword') && !attributes.confirmpassword.length) { // check if confirm password field has value
    errors.push('confirmpassword');
    errors.push('emptyValue'); // flag an empty value
  }

  _.each(attributes, (value, field) => { // check if the password and confirm password fields meet the password requirements
    if (field in validationReg) {
      if (!validationReg[field].test(value)) {
        errors.push(field);
        errors.push('required'); // flag password did not meet the requirements
      }
    }
  });
  errors = _.uniq(errors); // get unique values from errors[]
  if (errors.length) {
    if (options.error instanceof Function) {
      options.error(errors);
    }
    return errors;
  }
};

const AccountSetupView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    firstname:       '#firstname',
    lastname:        '#lastname',
    password:        '#password',
    confirmpassword: '#confirmpassword',
    form_group:      '.form-group',
    error:           '.error',
    input_error:     '.input-error',
    showPwd:         '#showPwd',
    pwdStrength:     '.password-strength-container',
    showConfirmPwd:  '#showConfirmPwd',
  },
  events: {
    //   firstname field
    'input @ui.firstname':       'onFocusFirstname',
    'focus @ui.firstname':       'onFocusFirstname',
    'blur @ui.firstname':        'onBlurFirstname',
    //   lastname field
    'input @ui.lastname':        'onFocusLastname',
    'focus @ui.lastname':        'onFocusLastname',
    'blur @ui.lastname':         'onBlurLastname',
    //    password field
    'input @ui.password':        'onChangePassword',
    'focus @ui.password':        'onFocusPassword',
    'blur @ui.password':         'onBlurPassword',
    //    confirm password field
    'input @ui.confirmpassword': 'onFocusConfirmpassword',
    'focus @ui.confirmpassword': 'onFocusConfirmpassword',
    'blur @ui.confirmpassword':  'onBlurConfirmpassword',

    'submit form': 'handleSubmit',
    change:        'onRender',
  },

  initialize: function fInitialize({ model, inviteKey, invitedBy }) {
    this.model = model;
    this.model.set({
      inviteKey,
      invitedBy,
      passwordRules: this.passwordRules,
      nameRules,
    });
  },

  onFocusFirstname: function fOnFocusFirstname() {
    this.ui.firstname.parent().removeClass('has-error');
  },

  onFocusLastname: function fOnFocusLastname() {
    this.ui.lastname.parent().removeClass('has-error');
  },

  onFocusPassword: function fOnFocusPassword() {
    this.ui.password.parent().removeClass('has-error');
  },

  onChangePassword: function fOnChangePassword() {
    this.onFocusPassword();
    this.pwdStrengthView.evaluate(this.ui.password.val());
  },

  onFocusConfirmpassword: function fOnFocusConfirmPassword() {
    this.ui.confirmpassword.parent().removeClass('has-error');
  },

  passwordRules: function fPasswordRules() {
    return [
      i18n.t('common:password-rules-minchars'),
      i18n.t('common:password-rules-lowercase'),
      i18n.t('common:password-rules-uppercase'),
      i18n.t('common:password-rules-numerical'),
      i18n.t('common:password-rules-special'),
      i18n.t('common:password-rules-confirm-match'),
    ];
  },

  handleSubmit: function fHandleSubmit(event) {
    event.preventDefault();

    this.model.set({
      firstname:       this.ui.firstname.val(),
      lastname:        this.ui.lastname.val(),
      password:        this.ui.password.val(),
      confirmpassword: this.ui.confirmpassword.val(),
    });

    const data = {};

    const modelAttributes = _.pick(this.model.attributes, fieldsToNotify);

    _.each(modelAttributes, (element, field) => {
      data[field] = element;
    }, this);

    const validateForm = validateData(data, { errors: [] }); // Process form validation

    if (validateForm) {
      // Validate
      if (Object.values(validateForm).indexOf('emptyValue') > -1) {
        this.warningView.show(i18n.t('common:info-not-saved'));
      } else if (Object.values(validateForm).indexOf('notMatched') > -1) {
        this.warningView.show(i18n.t('common:password-do-not-match'));
      } else if (Object.values(validateForm).indexOf('required') > -1) {
        this.warningView.show(i18n.t('common:incorrect-password'));
      }

      _.each(fieldsToNotify, function fieldsIterator(fieldName) {
        if (_.indexOf(validateForm, fieldName) !== -1) {
          this.setInputError(this.$el.find(`#${fieldName}`));
        }
      }, this);
    } else {
      $.ajax({
        url:  `${NETOP_CONFIG.SECURE_API}/invite/${this.model.get('inviteKey')}`,
        data: {
          inviteLogin:     false,
          firstname:       this.model.get('firstname'),
          lastname:        this.model.get('lastname'),
          password:        this.model.get('password'),
          confirmPassword: this.model.get('confirmpassword'),
        },
        method: 'POST',
      }).done((authResponse) => {
        this.model.set({ token: authResponse.token, home: true });
        navChannel.trigger('SUCCESS');
      }).fail((response) => {
        const errCode = getErrorCode(response);
        if (errCode === '1.16') {
          this.warningView.show(i18n.t('common:incorrect-password'));
          this.setInputError(this.$el.find('#password'));
        } else {
          this.warningView.show(i18n.t('common:info-not-saved'));
        }
      });
    }
  },

  setInputError: function fSetInputLabel(el) {
    el.each((index, elem) => {
      const $el = $(elem);
      $el.parent().addClass('has-error');
    });
  },

  mapErrorCodeToMessage: function fMapErrorMessage(code) {
        code = parseInt(code, 10); // eslint-disable-line
    let range = null;
    const i18nGenericError = 'error:unknown';
    const specificCodes = [20004, 20016];

    if (!specificCodes.includes(code)) {
      if (code >= 20000 && code < 30000) {
        range = 'adfs-range';
      }
      if (code >= 61000 && code < 61100) {
        range = 'ldap-range';
      }
    }
    let i18nMappedError = ['error', (range || code)].join(':');
    if (!i18n.exists(i18nMappedError)) {
      i18nMappedError = i18nGenericError;
    }
    return i18n.t(i18nMappedError, { code });
  },

  showPwdView:        null,
  showConfirmPwdView: null,
  pwdStrengthView:    null,

  onAttach: function fAttach() {
    this.showPwdView = new ShowPwdView({
      el:     this.ui.showPwd,
      target: this.ui.password,
    });
    this.showPwdView.render();
    this.showConfirmPwdView = new ShowPwdView({
      el:     this.ui.showConfirmPwd,
      target: this.ui.confirmpassword,
    });
    this.showConfirmPwdView.render();
    this.pwdStrengthView = new PwdStrengthView({
      el: this.ui.pwdStrength,
    });
    this.pwdStrengthView.render();
  },

  onRender: function fOnRender() {
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
    this.warningView = new WarningView({
      el: this.ui.input_error,
    });

    this.$el.find('.has-tooltip').popover({ trigger: 'hover' });
    // setTimeout(() => this.ui.firstname.focus(), 1); // Doesn't work without timeout, cba with proper fix
  },
});

module.exports = AccountSetupView;
