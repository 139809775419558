'use strict';

const Marionette = require('backbone.marionette');
const template = require('../templates/inviteNotAvailable.hbs');

const InviteNoteAvailable = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
  },

  events: {
  },

});

module.exports = InviteNoteAvailable;
