'use strict';

const Marionette = require('backbone.marionette');
const template = require('../../templates/showPwdView.hbs');

const ShowPwdView = Marionette.ItemView.extend({
  template,
  ui: {
    target: '.show-pwd',
  },
  events: {
    'click @ui.target': 'onClick',
  },

  target:     null,
  initialize: function fInitialize(options) {
    this.target = options.target;
    this.render();
  },

  onClick: function fOnClick(event) {
    event.preventDefault();
    const isPwd = this.target.attr('type') === 'password';
    this.$el.removeClass('hide-pwd').addClass(isPwd ? 'hide-pwd' : '');
    this.target.attr('type', isPwd ? 'text' : 'password');
  },
});

module.exports = ShowPwdView;
