'use strict';

const $ = require('jquery');

const SERVER_TIME_URL = `${NETOP_CONFIG.PORTAL_API}/v4/rpc/get-time`;
const LOCAL_TIMER_UPDATE_INTERVAL = 1000;

function ServerTime() {
  let dateTime = null;

  const startAutoUpdateLocalTime = () => {
    setInterval(() => {
      dateTime.setSeconds(dateTime.getSeconds() + 1);
    }, LOCAL_TIMER_UPDATE_INTERVAL);
  };

  const getFromServer = () => $.ajax({
    url: SERVER_TIME_URL,
  }).then((response) => {
    dateTime = new Date(response.timestamp);
    startAutoUpdateLocalTime();
    return dateTime;
  });

  const get = () => {
    let d = $.Deferred();

    if (dateTime !== null) {
      d.resolve(dateTime);
    } else {
      d = getFromServer();
    }

    return d;
  };

  const getSync = () => dateTime;

  return {
    get,
    getSync,
  };
}

module.exports = new ServerTime();
