var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"mb\">\n                    <a href=\"#\" id=\"forgot-password\">Forgot your password?</a>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(__default(require("../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"common:change-back",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":49}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(__default(require("../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"common:change-acc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":48}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <div class=\"modal-busy-layer\"/>\n    <div><div class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":56}}}))
    + "</div></div>\n    <div class=\"center-block username\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</div>\n    <div class=\"captcha-container\"></div>\n    <form>\n        <div class=\"error\"></div>\n        <div id='step-content'>\n            <div class=\"input-icon\">\n                <label for=\"password\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:password-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":70}}}))
    + "</label>\n                <input type=\"password\" class=\"input\" id=\"password\">\n            </div>\n            <div class=\"input-error\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"inviteLogin") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":18,"column":23}}})) != null ? stack1 : "")
    + "            <button type=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":51},"end":{"line":19,"column":73}}}))
    + "</button>\n        </div>\n        <div id=\"captcha_legal\">\n            This site is protected by reCAPTCHA and the Google\n            <a href=\"https://policies.google.com/privacy\" target=\"_blank\">Privacy Policy</a> and\n            <a href=\"https://policies.google.com/terms\" target=\"_blank\">Terms of Service</a> apply.\n        </div>\n    </form>\n        <div>\n            <a href=\"#\" class=\"link-sm\" id=\"cancel\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inviteLogin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </div>\n    <div class=\"modal-container-busy-layer\" tabindex=\"0\"/>\n</div>";
},"useData":true});