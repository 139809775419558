'use strict';

module.exports = function ifCond(...args) {
  const [v1, operator, v2, options] = args;
  if (args.length !== 4) {
    throw new Error('#ifCond helper requires 3 arguments: v1, operator, v2');
  }
  const compare = {
    '===': () => v1 === v2,
    '!==': () => v1 !== v2,
    '>':   () => v1 > v2,
    '>=':  () => v1 >= v2,
    '<':   () => v1 < v2,
    '<=':  () => v1 <= v2,
    '&&':  () => v1 && v2,
    '||':  () => v1 || v2,
  };
  if (!compare[operator]) {
    throw new Error(`#ifCond: invalid operator '${operator}'. Supported operators are: ${Object.keys(compare).join(', ')}.`);
  }
  return compare[operator]() ? options.fn(this) : options.inverse(this);
};
