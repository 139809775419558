var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div class=\"container-center\">\n    <div class=\"header\" id=\"header\">\n        <img class=\"center-block\" src=\"" + require("../../images/netop-new-dark.png") + "\" alt=\"Netop Remote Control\" />\n    </div>\n    <div class=\"middle\" id=\"middle\">\n        <div id=\"main-hook\" class=\"center-zone center-block\"></div>\n    </div>\n    <div class=\"footer\" id=\"footer\">\n      <a class=\"link\" href=\"https://www.imperosoftware.com/privacy/\" target=\"_blank\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:privacy-and-terms",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":85},"end":{"line":10,"column":120}}}))
    + "</a> | <a class=\"link\" href=\"https://status-nrc.netop.com/\" target=\"_blank\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:system-status",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":196},"end":{"line":10,"column":227}}}))
    + " </a>| "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"copyright") : depth0), depth0))
    + "\n    </div>\n  </div>\n  <div class=\"notification-bar\" id=\"notification-bar\"></div>\n</div>\n";
},"useData":true});