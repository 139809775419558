var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "- "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + " <br/>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-container\">\n    <div><div class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:change-password-title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":72}}}))
    + "</div></div>\n    <div class=\"center-block username\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</div>\n    <form>\n        <div class=\"error\"></div>\n        <div class=\"input-icon\">\n            <label class=\"control-label\" for=\"password\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:change-password-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":56},"end":{"line":7,"column":95}}}))
    + "</label>\n            <input type=\"password\" class=\"input\" id=\"password\">\n            <span id=\"pass_popover\" class=\"form-control-feedback new-form glyphicon glyphicon-info-sign blue has-tooltip\"\n                  data-toggle=\"popover\"\n                  data-html=\"true\"\n                  data-placement=\"auto top\"\n                  title='"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:password-rules",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":25},"end":{"line":13,"column":58}}}))
    + ":'\n                  data-content=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"passwordRules") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":80}}})) != null ? stack1 : "")
    + "\">\n            </span>\n        </div>\n        <div class=\"input-icon\">\n            <label class=\"control-label\" for=\"confirmpassword\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:confirm-password-label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":63},"end":{"line":18,"column":103}}}))
    + "</label>\n            <input type=\"password\" class=\"input\" id=\"confirmpassword\">\n            <span id=\"confirm_pass_popover\" class=\"form-control-feedback new-form glyphicon glyphicon-info-sign blue has-tooltip\"\n                  data-toggle=\"popover\"\n                  data-html=\"true\"\n                  data-placement=\"auto top\"\n                  title='"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:password-rules",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":25},"end":{"line":24,"column":58}}}))
    + ":'\n                  data-content=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"passwordRules") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":25,"column":80}}})) != null ? stack1 : "")
    + "\">\n            </span>\n        </div>\n        <button type=\"submit\" class=\"btn-blue\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":47},"end":{"line":28,"column":69}}}))
    + "</button>\n    </form>\n    <div class=\"form-group\">\n        <a href=\"#\" class=\"link-sm\" id=\"cancel\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:change-acc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":48},"end":{"line":31,"column":76}}}))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});